.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-hcenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-vcenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-col-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-col-vcenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex,
.flex-row,
.flex-col,
.flex-center,
.flex-hcenter,
.flex-vcenter,
.flex-col-center,
.flex-col-vcenter {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.flex.wrap,
.flex-row.wrap,
.flex-col.wrap,
.flex-center.wrap,
.flex-hcenter.wrap,
.flex-vcenter.wrap,
.flex-col-center.wrap,
.flex-col-vcenter.wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex.wrap-reverse,
.flex-row.wrap-reverse,
.flex-col.wrap-reverse,
.flex-center.wrap-reverse,
.flex-hcenter.wrap-reverse,
.flex-vcenter.wrap-reverse,
.flex-col-center.wrap-reverse,
.flex-col-vcenter.wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex.d-row-reverse,
.flex-row.d-row-reverse,
.flex-col.d-row-reverse,
.flex-center.d-row-reverse,
.flex-hcenter.d-row-reverse,
.flex-vcenter.d-row-reverse,
.flex-col-center.d-row-reverse,
.flex-col-vcenter.d-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex.d-col-reverse,
.flex-row.d-col-reverse,
.flex-col.d-col-reverse,
.flex-center.d-col-reverse,
.flex-hcenter.d-col-reverse,
.flex-vcenter.d-col-reverse,
.flex-col-center.d-col-reverse,
.flex-col-vcenter.d-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -moz-box-orient: vertical;
  -moz-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flex.d-column,
.flex-row.d-column,
.flex-col.d-column,
.flex-center.d-column,
.flex-hcenter.d-column,
.flex-vcenter.d-column,
.flex-col-center.d-column,
.flex-col-vcenter.d-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex.jc-start,
.flex-row.jc-start,
.flex-col.jc-start,
.flex-center.jc-start,
.flex-hcenter.jc-start,
.flex-vcenter.jc-start,
.flex-col-center.jc-start,
.flex-col-vcenter.jc-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex.jc-center,
.flex-row.jc-center,
.flex-col.jc-center,
.flex-center.jc-center,
.flex-hcenter.jc-center,
.flex-vcenter.jc-center,
.flex-col-center.jc-center,
.flex-col-vcenter.jc-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex.jc-end,
.flex-row.jc-end,
.flex-col.jc-end,
.flex-center.jc-end,
.flex-hcenter.jc-end,
.flex-vcenter.jc-end,
.flex-col-center.jc-end,
.flex-col-vcenter.jc-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex.jc-around,
.flex-row.jc-around,
.flex-col.jc-around,
.flex-center.jc-around,
.flex-hcenter.jc-around,
.flex-vcenter.jc-around,
.flex-col-center.jc-around,
.flex-col-vcenter.jc-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex.jc-between,
.flex-row.jc-between,
.flex-col.jc-between,
.flex-center.jc-between,
.flex-hcenter.jc-between,
.flex-vcenter.jc-between,
.flex-col-center.jc-between,
.flex-col-vcenter.jc-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex.ai-start,
.flex-row.ai-start,
.flex-col.ai-start,
.flex-center.ai-start,
.flex-hcenter.ai-start,
.flex-vcenter.ai-start,
.flex-col-center.ai-start,
.flex-col-vcenter.ai-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex.ai-center,
.flex-row.ai-center,
.flex-col.ai-center,
.flex-center.ai-center,
.flex-hcenter.ai-center,
.flex-vcenter.ai-center,
.flex-col-center.ai-center,
.flex-col-vcenter.ai-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex.ai-end,
.flex-row.ai-end,
.flex-col.ai-end,
.flex-center.ai-end,
.flex-hcenter.ai-end,
.flex-vcenter.ai-end,
.flex-col-center.ai-end,
.flex-col-vcenter.ai-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex.ai-baseline,
.flex-row.ai-baseline,
.flex-col.ai-baseline,
.flex-center.ai-baseline,
.flex-hcenter.ai-baseline,
.flex-vcenter.ai-baseline,
.flex-col-center.ai-baseline,
.flex-col-vcenter.ai-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -moz-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.flex.ai-stretch,
.flex-row.ai-stretch,
.flex-col.ai-stretch,
.flex-center.ai-stretch,
.flex-hcenter.ai-stretch,
.flex-vcenter.ai-stretch,
.flex-col-center.ai-stretch,
.flex-col-vcenter.ai-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.flex.ac-start,
.flex-row.ac-start,
.flex-col.ac-start,
.flex-center.ac-start,
.flex-hcenter.ac-start,
.flex-vcenter.ac-start,
.flex-col-center.ac-start,
.flex-col-vcenter.ac-start {
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.flex.ac-center,
.flex-row.ac-center,
.flex-col.ac-center,
.flex-center.ac-center,
.flex-hcenter.ac-center,
.flex-vcenter.ac-center,
.flex-col-center.ac-center,
.flex-col-vcenter.ac-center {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.flex.ac-end,
.flex-row.ac-end,
.flex-col.ac-end,
.flex-center.ac-end,
.flex-hcenter.ac-end,
.flex-vcenter.ac-end,
.flex-col-center.ac-end,
.flex-col-vcenter.ac-end {
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.flex.ac-around,
.flex-row.ac-around,
.flex-col.ac-around,
.flex-center.ac-around,
.flex-hcenter.ac-around,
.flex-vcenter.ac-around,
.flex-col-center.ac-around,
.flex-col-vcenter.ac-around {
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.flex.ac-between,
.flex-row.ac-between,
.flex-col.ac-between,
.flex-center.ac-between,
.flex-hcenter.ac-between,
.flex-vcenter.ac-between,
.flex-col-center.ac-between,
.flex-col-vcenter.ac-between {
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.flex-g-1 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.flex-no-shrink {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-col-1,
.flex-col-10,
.flex-col-11,
.flex-col-12,
.flex-col-2,
.flex-col-3,
.flex-col-4,
.flex-col-5,
.flex-col-6,
.flex-col-7,
.flex-col-8,
.flex-col-9 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.flex-col-1 {
  -webkit-flex-basis: 8.333%;
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  width: 8.333%;
}

.flex-col-2 {
  -webkit-flex-basis: 16.667%;
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  width: 16.667%;
}

.flex-col-3 {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  width: 25%;
}

.flex-col-4 {
  -webkit-flex-basis: 33.333%;
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  width: 33.333%;
}

.flex-col-5 {
  -webkit-flex-basis: 41.667%;
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  width: 41.667%;
}

.flex-col-6 {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  width: 50%;
}

.flex-col-7 {
  -webkit-flex-basis: 58.333%;
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  width: 58.333%;
}

.flex-col-8 {
  -webkit-flex-basis: 66.667%;
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  width: 66.667%;
}

.flex-col-9 {
  -webkit-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  width: 75%;
}

.flex-col-10 {
  -webkit-flex-basis: 83.333%;
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  width: 83.333%;
}

.flex-col-11 {
  -webkit-flex-basis: 91.667%;
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  width: 91.667%;
}

.flex-col-12 {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  width: 100%;
}

.flex-col-offset-1 {
  margin-left: 8.333%;
}

.flex-col-offset-2 {
  margin-left: 16.667%;
}

.flex-col-offset-3 {
  margin-left: 25%;
}

.flex-col-offset-4 {
  margin-left: 33.333%;
}

.flex-col-offset-5 {
  margin-left: 41.667%;
}

.flex-col-offset-6 {
  margin-left: 50%;
}

.flex-col-offset-7 {
  margin-left: 58.333%;
}

.flex-col-offset-8 {
  margin-left: 66.667%;
}

.flex-col-offset-9 {
  margin-left: 75%;
}

.flex-col-offset-10 {
  margin-left: 83.333%;
}

.flex-col-offset-11 {
  margin-left: 91.667%;
}

.flex-col-offset-2 {
  margin-left: 100%;
}