[class|='p-button'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 9px 25px;
  -webkit-border-radius: 24px;
          border-radius: 24px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}
.p-button-primary {
  color: #fff;
  background-color: #f73657;
}
.p-button-dashed {
  border: 1px solid #f73657;
  color: #f73657;
  background-color: #fff;
}
.p-button-dashed.ext:hover {
  position: relative;
}
.p-button-dashed.ext:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-border-radius: 24px;
          border-radius: 24px;
}
.pc-warp {
  width: 100%;
  min-width: 1200px;
  min-height: 100%;
  font-size: 14px;
  background-color: #fff;
}
.p-layout-index {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.p-layout-index .p-layout-head {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: transparent;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}
.p-layout-index .p-layout-head.white-bg {
  background: #fff;
  border-bottom: 1px solid rgba(43, 57, 64, 0.06);
}
.p-layout-index .p-layout-head > .wrap {
  min-width: 1200px;
  height: 80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.p-layout-index .p-layout-head .ql-logo {
  width: 120px;
  height: 40px;
}
.p-layout-index .p-layout-head .ql-logo-66 {
  width: 202.39px;
  height: 40px;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link {
  margin-left: 206px;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .tab-btn {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  padding: 0 25px;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .tab-btn.active {
  color: #f5333f;
  position: relative;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .tab-btn.active::after {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 24px;
  height: 3px;
  background: #f5333f;
  -webkit-border-radius: 2px;
          border-radius: 2px;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .download-app {
  margin-left: 24px;
  height: 40px;
  background: rgba(245, 51, 63);
  -webkit-border-radius: 24px 24px 24px 24px;
          border-radius: 24px 24px 24px 24px;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
  padding: 0 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .download-app:hover {
  background: rgba(245, 51, 63, 0.7);
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .download-app:hover .download-app-popover {
  display: block;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .download-app .download-app-popover {
  display: none;
  position: absolute;
  top: 72px;
  left: -5px;
  padding: 8px 8px 16px;
  font-weight: 400;
  font-size: 12px;
  color: #1A1A1A;
  line-height: 14px;
  text-align: center;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px 8px 8px 8px;
          border-radius: 8px 8px 8px 8px;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .download-app .download-app-popover .qr-code {
  width: 104px;
  height: 104px;
}
.p-layout-index .p-layout-head .p-layout-head-tab-link .download-app .download-app-popover::before {
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #FFF;
}
.p-layout-index .p-layout-head .ql-search {
  margin-left: 48px;
  width: 368px;
  padding: 9px 16px;
  -webkit-border-radius: 24px;
          border-radius: 24px;
  border: 1px solid #d9d9d9;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.p-layout-index .p-layout-head .ql-search input {
  width: 100%;
  height: 100%;
}
.p-layout-index .p-layout-head .button-box {
  position: absolute;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.p-layout-index .p-layout-head .button-box [class|='p-button'] {
  margin-left: 16px;
  width: 126px;
  height: 40px;
}
.p-layout-index .p-layout-head .button-box .ql-class {
  margin-left: 60px;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9;
}
.p-layout-index .p-layout-head .button-box .ql-class::after {
  content: '';
  margin-left: 4px;
  width: 10px;
  height: 7px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAAAXNSR0IArs4c6QAAAQxJREFUOBGlks0KgkAUhUd9iYRwY4tc5DLIVeCqbfQY9QS9Rk8TtAoK2gauAgODsGVbzWnOjMakjoldmNG5956PMz+ESOFOZ31p2eq3rDEKlev5i1earHqWfX9E4a3IN31Hnj/J0mRtWrYeR2GAXg7kMJrN2VqjhI7bQDmMZstc4zCoBqgBy3CGAhuIn1AZJiSEMCNDc+CcjPh6ecIRnAGWNyihdTCmobqmb877bcC3jDNrA22EHXZHmCkccWNNAjRk+ZnxZjEJZzkMqS8gEiooaizk/goMDZ9ngwVCtX1W+gmDvgJEUgFFCVHrTJQUQBQV0EYYdLUOUUCUoIQ/DekCRFeHGReF0UH6v+QNli+xcZPrOkAAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
          background-size: cover;
}
.p-layout-index .p-layout-head .button-box .ql-class .ql-class-float {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}
.p-layout-index .p-layout-head .button-box .ql-class .ql-class-float-sub {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
}
.p-layout-index .p-layout-head .button-box .ql-class:hover {
  color: #f73657;
}
.p-layout-index .p-layout-head .button-box .ql-class:hover .ql-class-float {
  display: block;
}
.p-layout-index .p-layout-head .button-box .ql-class:hover::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAAAXNSR0IArs4c6QAAAM9JREFUOBHFkU0KAjEMhZtR6GEUXDroQWZEvIsLbyKDehDFpQs9i1YcIm9KBMe26c5Af0jf+5qmxvwjXFlXGDl3kyYCiJkb6Ihoac/7Q8qTBAqM2Qw80LQaNArsw6QqojQ0CIzBcqA/wBAMVQEmT8c+VukXMAZD3zyQGw36AaZg8rM5mg6YI0SFCE1LbrYac/u8aE/xOD9HocNiUtjT9spEGzHEmi3nWNEC9FU+Czkw7HF3w76Le1mvH9Pqhdslp63QwgNvUOvmi1HwIJHse95mYKtWj9nPjgAAAABJRU5ErkJggg==);
}
.p-layout-index .p-layout-head .button-box .ql-class .float-menu {
  min-width: 120px;
  background: #fff;
  -webkit-box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -8px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -8px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 4px;
          border-radius: 4px;
}
.p-layout-index .p-layout-head .button-box .ql-class .float-menu > li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  position: relative;
  width: 100%;
}
.p-layout-index .p-layout-head .button-box .ql-class .float-menu > li a {
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  color: #2b3940;
  min-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.p-layout-index .p-layout-head .button-box .ql-class .float-menu > li:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#f73657), to(#f73657)) no-repeat right center / 3px 80%;
  background: -webkit-linear-gradient(#f73657, #f73657) no-repeat right center / 3px 80%;
  background: -moz-linear-gradient(#f73657, #f73657) no-repeat right center / 3px 80%;
  background: linear-gradient(#f73657, #f73657) no-repeat right center / 3px 80%;
}
.p-layout-index .p-layout-head .button-box .ql-class .float-menu > li:hover > a {
  color: #f73657;
}
.p-layout-index .p-layout-head .button-box .ql-class .float-menu > li:hover .ql-class-float-sub {
  display: block;
}
.p-layout-index .p-layout-head .button-box .download-btn {
  position: relative;
}
.p-layout-index .p-layout-head .button-box .download-btn .qr-code-box {
  display: none;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 10px;
  width: 176px;
  background-color: #fff;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 9;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  -webkit-box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -8px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -8px rgba(0, 0, 0, 0.12);
  padding: 20px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  color: rgba(43, 57, 64, 0.65);
  font-weight: bold;
}
.p-layout-index .p-layout-head .button-box .download-btn .qr-code-box img {
  width: 136px;
  height: 136px;
  margin-bottom: 8px;
}
.p-layout-index .p-layout-head .button-box .download-btn:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-border-radius: 24px;
          border-radius: 24px;
}
.p-layout-index .p-layout-head .button-box .download-btn:hover .qr-code-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.p-layout-index .p-layout-head .ql-user-poster {
  margin-left: 16px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
          border-radius: 50%;
  overflow: hidden;
}
.p-layout-index .p-layout-footer {
  border-top: 2px solid red;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 0 24px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.p-layout-index .p-layout-footer .relevant,
.p-layout-index .p-layout-footer .copyright {
  width: 1200px;
}
.p-layout-index .p-layout-footer .relevant {
  padding: 48px 0;
  border-bottom: 1px solid rgba(43, 57, 64, 0.1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.p-layout-index .p-layout-footer .relevant .relevant-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.p-layout-index .p-layout-footer .relevant .relevant-list ul + ul {
  margin-left: 96px;
}
.p-layout-index .p-layout-footer .relevant .relevant-list > ul > li {
  font-size: 16px;
  color: #4D4D4D;
  line-height: 24px;
}
.p-layout-index .p-layout-footer .relevant .relevant-list > ul > li:first-child {
  font-weight: bold;
}
.p-layout-index .p-layout-footer .relevant .relevant-list > ul > li a {
  color: #4D4D4D;
}
.p-layout-index .p-layout-footer .relevant .relevant-list > ul > li:first-of-type {
  color: #1A1A1A;
  margin-bottom: 24px;
}
.p-layout-index .p-layout-footer .relevant .relevant-list > ul > li:not(:first-of-type) {
  margin-top: 16px;
}
.p-layout-index .p-layout-footer .relevant .qr-box-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.p-layout-index .p-layout-footer .relevant .qr-box-list .qr-box:not(:last-child) {
  margin-right: 24px;
}
.p-layout-index .p-layout-footer .relevant .qr-box-list .qr-box .qr-name {
  font-weight: bold;
  font-size: 16px;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 24px;
  line-height: 24px;
}
.p-layout-index .p-layout-footer .relevant .qr-box-list .qr-box img {
  width: 96px;
  height: 96px;
}
.p-layout-index .p-layout-footer .copyright {
  margin-top: 24px;
}
.p-layout-index .p-layout-footer .copyright p {
  font-size: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #808080;
}
.p-layout-index .p-layout-footer .copyright p:first-of-type {
  margin-bottom: 16px;
}
.p-layout-index .p-layout-footer .copyright p span + span {
  margin-left: 80px;
  display: inline-block;
}
.p-layout-index .p-layout-footer .copyright p a {
  color: #808080;
}
.p-layout-index .p-layout-body {
  width: 1200px;
}
.p-layout-index .p-layout-body.home-training-page {
  width: 100%;
}
.p-layout-index .p-layout-main {
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.p-layout-index .p-layout-main .p-main-right {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 264px;
  padding: 15px 10px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: 88px;
}
.p-layout-index .p-layout-main .p-main-right > .right-module:not(:first-of-type) {
  margin-top: 32px;
}
.p-layout-index .p-layout-main .p-main-right > .right-module p.t {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(43, 57, 64, 0.15);
  color: #2b3940;
  font-size: 16px;
}
.p-layout-index .p-layout-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.p-layout-index .p-layout-dialog > ._bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.p-layout-index .p-layout-dialog > ._container {
  min-width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.p-layout-index .p-layout-dialog > ._container .dialog-title {
  font-size: 24px;
  color: #2b3940;
  text-align: center;
  padding-bottom: 24px;
}
.p-layout-index .p-layout-dialog > ._container .dialog-close {
  position: absolute;
  right: 17px;
  top: 17px;
  width: 14px;
  height: 14px;
}
.p-layout-index .p-icon-close {
  display: block;
  width: 20px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAZtJREFUaAXtmfFKxDAMh0/9V5/MBzgfUB9SEOYvRzOKpCNtkzRgCyOjW5vvS3esx2633XYFdgXEChzH8Y7jQ7wY2AmGO7F0pSzw34g/OJZJIDfBEwOx6CToxjIA4dGWSCAzwxcMWeJZWJo39L1U/XT+iVnCVoLgkfNL4HituNqnBIuDKl+3kJVAwr+VJwbKTVL6hgHhEgRZYBHO1g/PmpgiTAK5bOEjJdzgFRJ9zyVPWEV3eM518TgNS4TBe0iEw1tKeKwm86niTPWWw7PhiEQa+BGJdPA9EmnhNRLp4RUS0qZw+N3B+Vwiqi3tZ9B9tvGNmQuxMClQWxIu8NIfGgGrq+up6+5MN6P60vb7fH5w4rIKJjVowBMwHXXLJwE6qfIPUFyTfhN5JK7geWnTSmjg00r0wKeTGIFPIzEDv1zCAn6ZhCV8uIQHfJgE4N1fRG453Cbm8lfRPJf5hBVs69Qsp9lELdKL/unc0xNcwGkvTTFgsPSJKfw/rCAx9J1s6ba3ktDD8zKXlQivPOfnWCR0Xyh50I67Av+oAr8Tlxm43kurpgAAAABJRU5ErkJggg==) no-repeat center / cover;
  cursor: pointer;
}
.p-layout-index .qr-dialog ._container {
  width: 320px;
}
.p-layout-index .qr-dialog .qr-content {
  width: 176px;
  height: 176px;
}
.p-layout-index .qr-dialog .qr-content > img {
  width: 100%;
  height: 100%;
}
.p-layout-index .qr-dialog .qr-tips {
  margin-top: 29px;
  color: rgba(43, 57, 64, 0.85);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
}
.p-layout-index .p-to-top-btn {
  position: fixed;
  right: 36px;
  bottom: 140px;
  width: 56px;
  height: 48px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAABgCAYAAADFNvbQAAAAAXNSR0IArs4c6QAABRdJREFUeAHt3M9rFGccBvB3ZndNNrohm9jG1GB+VJGSQqGCXkRsEaF/gXgRepcWPBl62Vs8CVXvBS/iX1Ao1i6Si5YcPRREtBhT0d2EbOomm/3ReUbfEsK7b9KqO9/v8AwsS77vxH32+WQ2kzjZwGzbLs/NFU8fO36uMFg4n81mZ4IgGNm2Cz/sYQOdTqfSbDYf1lZrt8oLD25fmZ1d3vrwgf2gVCqFJ06eujg8NHQ1QsvYOe/lNBBhtqorK5fuz9+7EXm1kSwGBN5XZ87+lM/nL2D40f6R+Nbf12cyGVqik6S2Vqtl1jc2zMtXlfiGHPV6/eZvd375FohZDHDkAS+Xy5npyQkzWNiHMTcBDeAA2jswYPYeGjDDxaJ5/OQpUl2IzBai+2sZfM/7cubzX6OXzfDIp9PEE4DWLUJf3x4zEGFWqlWT7+8/u39q8nqIExZ8z8PLJo+8btXJmcMIVjCDXYizTcTDkJuOBqwV7EL8qIDYOGHhpqMBawW7MDoU40OPZ5s68JDSWsEu1BObSV0NENDViqIZARVhuaIS0NWKohkBFWG5ohLQ1YqiGQEVYbmiEtDViqIZARVhuaIS0NWKohkBFWG5ohLQ1YqiGQEVYbmiEtDViqIZARVhuaIS0NWKohkBFWG5ohLQ1YqiGQEVYbmiEtDViqIZARVhuaIS0NWKohkBFWG5ohLQ1YqiGQEVYbmixn9e5lrQPquvr5tni0vx0xg/OIa/5tH+lJz5Uwn4+nXd/PHokWk2W/GTXvt7zRw9fDj606y8swTNw9S9hG7HAw4gAYq1tG2pAnThWbC0IqYG0IeXZsRUAHbDGzswanDbuqXtSFQP6MMb/2TM4JZmRNWAO+HZIy/NiGoBd4uXdkSVgP8VL82I6gD/L15aEVUBviteGhHVAL4vvLQhqgB833hpQhQP+KHw0oIoGvBD46UBUSxgr/C0I4oE7DWeZkRxgEnhaUUUBZg0nkZEMYC4hmXrZRC2TPxPAn4Z3evN9wtwZJWyiQHEBUj2GhZbTlJ49vG7IS4+f3OxlN0vyXsxgNtLSBrP5nEh2jUJ92KuSsOlf41GwzQ2G2ZsdNQcGP1YQj9xBiBmo3ePX3rxwuRye8zBBF7Su5UhBhDXbc58drRbzsTn+IKS9EVlCxH7EmoD8t7fAAH9/YhfJaB4In9AAvr7Eb9KQPFE/oAE9PcjfpWA4on8AQno70f8KgHFE/kDEtDfj/hVAoon8gckoL8f8asEFE/kD0hAfz/iVwkonsgfkID+fsSvElA8kT8gAf39iF8loHgif0AC+vsRv0pA8UT+gAT09yN+lYDiifwBCejvR/wqAcUT+QMS0N+P+FUCiifyBySgvx/xqwQUT+QPGHY6nQp2abXevEG4f3euSmjAWsEubDabDxFqfWNDQjZm2EUD1gp2YW21dguf8/JVfCDu4tO5S9INWCvYheWFB7ejQ7GF4WptLelsfPwdGoARrGAGuwD7/3zn7ncjxeKPuVzOTE9OmMHCvh3+GS4n0QDwHj95ajY3N01lefn7b858fS2DIEemp34fPzQxFYbhF5VqNd4BmJkwNNEsiax8zLcN4IQFb2vyfOkv8+ezRdNut029Xr85X777Q7lc7sRHIPYtlUrhiZOnLg4PDV0NgiCGZYuyGsDLZnVl5dL9+Xs3Iq820v0LaKNenpsrnj52/FxhsHA+m83ORJgjdo33vW8APyrgbBMnLPied2V2dnlrin8AnKxjd2tSROAAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
          background-size: cover;
}
.p-layout-index .p-to-top-btn:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAABgCAYAAADFNvbQAAAAAXNSR0IArs4c6QAABPtJREFUeAHt3U9oU1kUBvDzXl5sG01p2s5iQOwfkVlUGFHUjYgziDAbcacupuMsleJAVxZnkcVAXQlqUXcybpwuZyWI4wTpxkpn3Ai6carUjqMmLY2Q1r7kec8bbinlJgqtyf1uzoPy6G1Kvvv9OMlLE9GjNce50dHMoT37jqfb0yeDIBjwPK9rzU3k2zo2EEVRPgzDx8WF4q3c1OT4hZGRudV37+lvstmsv//AwaHOjo6LCi2h1+VsTwMKs1yYnx9+MHF/THlVOFkMyHjfHD5yo62tbZAXv+juir9aW1ookRBL7qRRR7lcpsWlJXrzNh9/cY5SqXTzz7t3fmTEgBd48hgvmUxSf28Ptae38LIcFjTAA7Q5laLN21LUmcnQs+nnnGpQmU2p8+UEP+ftHtj5h3rY9Hds7xc8C9CqRWhp2UQphZkvFKittfVId1/vFZ8vWPg5jx82ZfKqVWfPOhuxFZuxnc9XmxyPF+XAaEBbsZ3PLxU4Nl+wyIHRgLZiO1+NYjx6crWJgccptRXb+TixJampAQE0tQK0JoBAWKaoAmhqBWhNAIGwTFEF0NQK0JoAAmGZogqgqRWgNQEEwjJFFUBTK0BrAgiEZYoqgKZWgNYEEAjLFFUATa0ArQkgEJYpqgCaWgFaE0AgLFNUATS1ArQmgEBYpqgCaGoFaE0AgbBMUQXQ1ArQmgACYZmiCqCpFaA1AQTCMkWN/3mZ6Qfoa9E/MxRe/S3eRnDmBHl9W9G3ZMzvJGD0dJreD/1C0cK7eNOVR09o09jP5H3VaywBedG5h9C1eIzDkDGognXtcArQhKfBXEV0BrAWnsuITgBWwwtOHaPgh2PaLz67NonwgDXxTp8gvgJ1GREa8GN4evRcRoQF/FQ81xEhAaviqee7QD1sVjtcnEQ4wJp46vnuY4driFCA68XTuC4hwgBuFJ5riBCAG43nEqL1gJ8LzxVEqwE/N54LiNYC1gsPHdFKwHrjISNaB9goPFREqwAbjYeIaA0gf4Zl9ccgVsrkP499wl9Y9O036lzzxb7KasthDWB4bXzlMyy6HH4bqBF4K/df5a2o8Pq4vknDz9YArm2i0Xg6j2kS9c9sOFvzqTQuKpp9TdF/eQoGj1Li+6M29BNn4GxeOkXhr7+T92V3zXc86h3am5z6O+I73bt7V73vW+5vHQ08/OtR/NvWPoSuY29N9asCCM4tgAII3gB4fJlAAQRvADy+TKAAgjcAHl8mUADBGwCPLxMogOANgMeXCRRA8AbA48sECiB4A+DxZQIFELwB8PgygQII3gB4fJlAAQRvADy+TKAAgjcAHl8mUADBGwCPLxMogOANgMeXCUQHjKIoz3sol8vgW2me+NqK7fwwDB/z1heXlpqnAfCdaiu284sLxVu8nzdv40EE31pzxNdWbOfnpibH1SiWeXGh+P//s9AcNWDuko3Yis3YzuNt3L5772xXJnMpmUxSf28Ptae3YO7O8dSM92z6OS0vL1N+bu6n7w5/eznBe97R3/dw67aePt/3v84XCvENGDPh+6TWHK/F7u3xBUtpcZFm/31FL2ZeUqVSoVKpdHMid+98LpeL4gnkLWSzWX//gYNDnR0dFz3Pi2Ht3lrzpeOHzcL8/PCDiftjyqvCDawA6jrOjY5mDu3Zdzzdnj4ZBMGAwuzSP5Nz/Rvglwp8tckXLPycd2FkZG51ig+S5GaDofVBOAAAAABJRU5ErkJggg==);
}
.p-layout-index .p-toast {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}
.p-layout-index .p-toast.closed {
  display: none;
}
.p-layout-index .p-toast .bd {
  line-height: 1.5;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 90%;
  padding: 15px;
  -webkit-transition: opacity 0.6s linear;
  -moz-transition: opacity 0.6s linear;
  transition: opacity 0.6s linear;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  opacity: 1;
  color: #fff;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}
.p-layout-index .p-toast .cnt {
  font-size: 16px;
  text-align: center;
}
